import request from "@/utils/request";

// 获取客服端口
export function getContactMold(params) {
    return request({
        url: '/admin/base/getContactMold',
        method: 'get',
        params
    })
}

// 客服列表
export function ContactList(params) {
    return request({
        url: '/admin/base/contactList',
        method: 'get',
        params
    })
}

// 添加客服
export function ContactAdd(data) {
    return request({
        url: '/admin/base/contactAdd',
        method: 'post',
        data
    })
}

// 编辑客服
export function ContactEdit(data) {
    return request({
        url: '/admin/base/contactEdit',
        method: 'put',
        data
    })
}

// 删除客服
export function ContactDel(data) {
    return request({
        url: '/admin/base/contactDel',
        method: 'delete',
        data
    })
}

// 调整客服概率
export function ContactChance(data) {
    return request({
        url: '/admin/base/contactChance',
        method: 'put',
        data
    })
}

// 客服上传COS签名
export function contactCosToken(params) {
    return request({
        url: '/admin/base/contactCosToken',
        method: 'get',
        params
    })
}