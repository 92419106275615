<template>
  <div>
    <el-card style="margin-bottom: 100px">
      <div class="navs">
        <div class="headers">
          <ul>
            <li
              :class="{ selected: tabs == i }"
              @click="toggletabs(i, item.key)"
              v-for="(item, index, i) in contactMoldData"
              :key="index"
            >
              {{ item.value }}
            </li>
          </ul>
        </div>
        <div>
          <el-button @click="toAdd" type="primary">添加客服</el-button>
        </div>
      </div>
      <div class="contents">
        <el-table
          :data="contactList"
          stripe
          ref="userTable.multipleSelection"
          header-cell-class-name="table-header"
          border
        >
          <el-table-column
            prop="mold"
            label="客服端"
            align="center"
          ></el-table-column>
          <el-table-column
            label="客服名"
            prop="name"
            align="center"
          ></el-table-column>
          <el-table-column label="微信号" prop="wechat" align="center">
          </el-table-column>
          <el-table-column label="二维码" prop="qrcode_url" align="center">
            <template slot-scope="scope">
              <div @click="openThumb(scope.row.qrcode_url)" class="thumb_box">
                <img
                  style="width: 50px; height: 50px"
                  :src="scope.row.qrcode_url"
                  alt=""
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="出现概率"
            prop="chance"
            align="center"
            width="350"
          >
            <template slot-scope="scope">
              <div class="progress_box">
                <el-slider
                  class="chance_slider"
                  v-model="scope.row.chance"
                  :show-tooltip="false"
                  @change="
                    changeChance(
                      scope.row.chance,
                      scope.row.id,
                      scope.row.chanceNum
                    )
                  "
                ></el-slider>
                <span class="chance_num">{{ scope.row.chance }}%</span>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="toEditFifty(scope.row)"
                size="small"
              ></el-button>
              <el-button
                type="danger"
                icon="el-icon-delete"
                circle
                @click="removeData(scope.row.id)"
                size="small"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!-- 缩略图详情 -->
    <el-dialog
      title="缩略图详情"
      :visible.sync="detailsDialog"
      :center="true"
      width="40%"
    >
      <div style="width: 100%; height: 100%">
        <img style="width: 100%; height: 100%" :src="detailsImg" alt="" />
      </div>
    </el-dialog>

    <!-- 添加客服 -->
    <el-dialog
      :visible.sync="editFiftyDialog"
      :title="addTitle"
      append-to-body
      width="60%"
      :close-on-click-modal="false"
      @close="editDialogClose"
      center
    >
      <el-form :model="editForm" ref="editForm">
        <el-form-item el-form-item label="客服名" label-width="100px">
          <el-input
            v-model="editForm.name"
            placeholder="请输入客服名"
          ></el-input>
        </el-form-item>

        <el-form-item el-form-item label="微信号" label-width="100px">
          <el-input
            v-model="editForm.wechat"
            placeholder="请输入微信号"
          ></el-input>
        </el-form-item>
        <el-form-item el-form-item label="描述" label-width="100px">
          <el-input
            type="textarea"
            :rows="4"
            v-model="editForm.desc"
            placeholder="请输入客服描述"
          />
        </el-form-item>
        <el-form-item el-form-item label="客服二维码" label-width="100px">
          <el-upload
            class="avatar-uploader"
            action="123"
            :show-file-list="false"
            accept="image/*"
            :before-upload="beforeUpload"
            style="width: 180px;height:180px"
          >
            <img
              v-if="thumbSrc"
              style="width: 100%;height:100%"
              :src="thumbSrc"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label-width="40%">
          <el-button v-if="isAdd == 'add'" type="primary" @click="addNow"
            >立即添加</el-button
          >
          <el-button v-else type="primary" @click="editNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import {
  getContactMold,
  ContactList,
  ContactAdd,
  ContactEdit,
  ContactDel,
  ContactChance,
  contactCosToken
} from "@/api/service.js";
import { warningDialog } from "@/utils/dialog";
var COS = require("cos-js-sdk-v5");
import { log } from "util";
export default {
  data() {
    return {
      contactMoldData: [],
      tabs: 0,
      type: "",
      contactList: [],
      editFiftyDialog: false,
      editForm: {
        mold: "",
        name: "",
        wechat: "",
        desc: "",
        qrcode: ""
      },
      isAdd: "",
      addTitle: "",
      thumbSrc: "",
      detailsDialog: false,
      detailsImg: "",
      chanceCount: 0
    };
  },

  created() {
    this.getContactMoldData();
  },

  methods: {
    // 查看缩略图详情
    openThumb(src) {
      this.detailsDialog = true;
      this.detailsImg = src;
    },
    // 获取客服端口
    getContactMoldData() {
      getContactMold().then(res => {
        this.contactMoldData = res.data;
        this.type = "官网网校端";
        this.getContactList();
      });
    },
    // tab栏切换
    toggletabs(index, type) {
      this.tabs = index;
      this.type = type;
      this.getContactList();
    },

    // 客服列表
    getContactList() {
      ContactList({ mold: this.type }).then(res => {
        this.chanceCount = res.data.chance;
        res.data.list.forEach(item => {
          item.chanceNum = item.chance;
        });
        this.contactList = res.data.list;
      });
    },
    toAdd() {
      this.isAdd = "add";
      this.addTitle = "添加客服";
      this.editFiftyDialog = true;
    },

    // 选择图片
    beforeUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      contactCosToken({ suffix: file.name }).then(res => {
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {}
          },
          function(err, data) {
            if (data) {
              that.editForm.qrcode = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = e => {
                that.thumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 点击立即添加
    addNow() {
      this.editForm.mold = this.type;
      if (this.editForm.name == "") {
        this.$message.error("请输入客服名");
        return;
      } else if (this.editForm.wechat == "") {
        this.$message.error("请输入微信号");
        return;
      } else if (this.editForm.desc == "") {
        this.$message.error("请输入客服描述");
        return;
      } else if (this.editForm.qrcode == "") {
        this.$message.error("请上传客服二维码");
        return;
      }
      ContactAdd(this.editForm).then(res => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加客服成功");
        this.editFiftyDialog = false;
        this.getContactList();
      });
    },

    // 点击编辑图标
    toEditFifty(item) {
      this.isAdd = "edit";
      this.addTitle = "编辑客服";
      let data = JSON.parse(JSON.stringify(item));
      this.editForm = data;
      this.thumbSrc = this.editForm.qrcode_url;
      this.editFiftyDialog = true;
    },

    // 修改弹窗关闭触发
    editDialogClose() {
      this.editForm = {
        mold: "",
        name: "",
        wechat: "",
        desc: "",
        qrcode: ""
      };
      this.thumbSrc = "";
    },

    // 点击立即修改
    editNow() {
      if (this.editForm.name == "") {
        this.$message.error("请输入客服名");
        return;
      } else if (this.editForm.wechat == "") {
        this.$message.error("请输入微信号");
        return;
      } else if (this.editForm.desc == "") {
        this.$message.error("请输入客服描述");
        return;
      } else if (this.editForm.qrcode == "") {
        this.$message.error("请上传客服二维码");
        return;
      }
      ContactEdit(this.editForm).then(res => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改客服成功");
        this.editFiftyDialog = false;
        this.getContactList();
      });
    },

    // 点击删除客服
    async removeData(id) {
      const result = await this.$confirm("是否要删除该客服?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      ContactDel({ id: id }).then(res => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除客服成功");
        this.getContactList();
      });
    },

    // 修改客服出现概率
    changeChance(per, id, numb) {
      let data = {
        id: id,
        chance: per
      };

      let num = 100 - this.chanceCount;
      // console.log('原数',numb);
      // console.log("用户操作", per);
      // console.log("剩余", num);
      if (per > num && num !== 0 && numb < per) {
        data.chance = num + numb;
      }
      ContactChance(data).then(res => {
        this.getContactList();
        if (res.code !== 1) {
          return;
        }
        this.$message.success("修改客服概率成功");
        this.getContactList();
      });
    }
  }
};
</script>

<style scoped lang="less">
.avatar {
  z-index: 999;
}

* {
  box-sizing: border-box;
}
li {
  list-style: none;
  cursor: pointer;
}
.navs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #323232;
  padding: 0 20px;
  .headers {
    border-bottom: 1px solid #eeeeee;
    height: 120px;
    line-height: 50px;
    width: 100%;
    height: 50px;
    ul {
      width: 100%;
      display: flex;
      li {
        height: 50px;
        // float: left;
        margin-right: 60px;
      }
    }
    .selected {
      color: #38a28a;
      border-bottom: 1px solid #38a28a;
    }
  }
}
.contents {
  margin-top: 10px;
}

.progress_box {
  display: flex;
  align-items: center;
  .chance_slider {
    flex: 1;
  }
  .chance_num {
    width: 50px;
  }
}
.thumb_box {
  width: 100%;
  height: 100%;
  cursor: pointer;
  img {
    width: 160px;
    height: 90px;
  }
}

::v-deep .el-upload--text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
